<template>
  <div>
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Search Term -->
          <search-filter :searchTermValue="searchTerm" @input="(newsearchTerm) => {searchTerm = newsearchTerm}"></search-filter>
          <!-- Within -->
          <select-filter
            labelname="Within"
            :options="withinOptions"
            optionsName="withinName"
            :value="withinSelected"
            @input="(newWithin) => {withinSelected = newWithin}"
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
            @inputToDate="(newToDate) => {toDate = newToDate}"
          ></date-range-picker>
          <vs-button type="filled" @click="getSubmittedFormList()" class="small-btn bg-actionbutton">{{ LabelConstant.buttonLabelRunQuery }}</vs-button>
        </div>
      </div>
    </div>
    <!-- Form Captures Table -->
    <vx-card>

         <!-- Table Title -->
         <div class="table-title">
            <h2>Forms Captured</h2>
            <div class="actions-button">
               <vx-tooltip color="actionbutton" text="EXCEL" class="float-left">
                  <vs-button type="filled" class="bg-actionbutton">
                     <download-excel class="btn btn-default" :data="excelData" :exportFields="excelFields" worksheet="Forms Captured" name="Forms Captured.xls">
                        <img src="@/assets/images/excel.svg" />
                     </download-excel>
                  </vs-button>
               </vx-tooltip>
            </div>
         </div>
         <div class="common-table">      
            <!--Table Select Row-->
            <div class="table-row-select">
               <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recordList" />
               </vs-select>
            </div>
            <vs-table id="tableDataFormsCaptured" ref="tableDataFormsCaptured" :data="formCapturesData" search :max-items="perPage" pagination class="big-search-table">
               <template slot="thead">                
                  <vs-th></vs-th>
                  <vs-th sort-key="sortableCreatedDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                  <vs-th sort-key="Form_Name">{{ LabelConstant.tableHeaderLabelFormName }}</vs-th>
                  <vs-th sort-key="First_Name">{{ LabelConstant.tableHeaderLabelFirstName }}</vs-th>
                  <vs-th sort-key="Last_Name">{{ LabelConstant.tableHeaderLabelLastName }}</vs-th>
                  <vs-th sort-key="Email_Name">{{ LabelConstant.tableHeaderLabelEmail }}</vs-th>
               </template>
               <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                     <vs-td width="90">
                        <div class="table-action">
                           <!-- <vs-button
                           @click="detailspopupActive=true, getCompanyDetailsByEmail(tr.Key)"
                           title="View Details"                          
                           type="filled"
                           icon-pack="feather"
                           icon="icon-search"
                           class="mr-0 bg-actionbutton"
                           ></vs-button> -->
                           <vs-button
                           @click="detailspopupActive=true, getCompanyDetails(tr.Visitor_Id)"                          
                           title="View Prospect"
                           type="filled"
                           icon-pack="feather"
                           icon="icon-search"
                           class="mr-2 float-left"
                           color="primary"
                           ></vs-button>
                           <vs-button
                           @click="isViewFormData=true, getIndividualSubmittedForm(tr.Id)"                          
                           title="View Form Data"
                           type="filled"
                           icon-pack="feather"
                           icon="icon-menu"
                           class="mr-0 float-left"
                           color="primary"
                           ></vs-button>
                        </div>
                     </vs-td>
                     <vs-td :data="tr.sortableCreatedDate">
                           {{tr.Date_Created}}
                     </vs-td>
                     <vs-td :data="tr.Form_Name">
                           {{tr.Form_Name}}
                     </vs-td>
                     <vs-td :data="tr.First_Name">
                           {{tr.First_Name}}
                     </vs-td>
                     <vs-td :data="tr.Last_Name">
                           {{tr.Last_Name}}
                     </vs-td>
                     <vs-td :data="tr.Email_Name">
                           {{tr.Email_Name}}
                     </vs-td>
                  </vs-tr>
               </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{formCapturesData.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{formCapturesData.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>        
         </div>        
               
		</vx-card>  
    <!-- Contact Us popup -->
    <vs-popup class="company-details-modal close-open" :title="viewFormData.Form_Name" v-if="isViewFormData" :active.sync="viewFormDataPopupActive">
        <vs-button
          @click="closeViewFormData()"
          color="secondary"
          type="filled"
          class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
        <div class="add-track-no">
          <ul class="contact-details">
            <li>
                <feather-icon icon="CalendarIcon" class="text-secondary"></feather-icon>
                <div>
                    <h3>Capture Date</h3>
                    <span>{{viewFormData.Date_Created}}</span>
                </div>
            </li> 
            <li>
                <feather-icon icon="CalendarIcon" class="text-secondary"></feather-icon>
                <div>
                    <h3>Email</h3>
                    <span>{{viewFormData.Email_Name}}</span>
                </div>
            </li> 
            <li>
                <feather-icon icon="UserIcon" class="text-secondary"></feather-icon>
                <div>
                    <h3>First Name</h3>
                    <span>{{viewFormData.First_Name}}</span>
                </div>
            </li>
            <li>
                <feather-icon icon="UserIcon" class="text-secondary"></feather-icon>
                <div>
                    <h3>Last Name</h3>
                    <span>{{viewFormData.Last_Name}}</span>
                </div>
            </li>
            <li>
                <feather-icon icon="GlobeIcon" class="text-secondary"></feather-icon>
                <div>
                    <h3>Full URL</h3>
                    <span>{{viewFormData.Full_URL}}</span>
                </div>
            </li>           
          </ul>
          <vs-table :data="viewFormData.Fields_All">
            <template slot="thead">                
              <vs-th>Form Field</vs-th>
              <vs-th>Form Field Value</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.Key">
                        {{tr.Key}}
                    </vs-td>
                    <vs-td :data="tr.Value">
                        {{tr.Value}}
                    </vs-td>
                </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="flex justify-end w-full float-left mt-6 mb-6">
          <vs-button @click="closeViewFormData()" color="secondary" type="filled">{{ LabelConstant.buttonLabelClose }}</vs-button>          
        </div>
    </vs-popup>
    <!-- Company Details popup -->
    <company-details-modal
      :latestVisitorId="latestVisitorId"
      :searchLeadDetailsData="searchLeadDetails"
      @inputSearchLeadDetails="(newSearchLeadDetails) => {searchLeadDetails = newSearchLeadDetails}" 
      :pageViewsData="pageViewsCompany" 
      @inputPageViews="(newpageViewsCompany) => {pageViewsCompany = newpageViewsCompany}" 
      :keywordsData="keywords" 
      @inputKeywords="(newKeywords) => {keywords = newKeywords}" 
      :allVisitorUniqueDetailShortData="allVisitorUniqueDetailShort" 
      @inputAllVisitorUniqueDetailShort="(newAllVisitorUniqueDetailShort) => {allVisitorUniqueDetailShort = newAllVisitorUniqueDetailShort}" 
      :detailspopupActive='detailspopupActive' 
      @close="closepopup"
      @getCompanyDetails="getCompanyDetails(latestVisitorId)">
    </company-details-modal>
    <!-- Help popup -->
    <help-modal :popupActive='popupActive' @close="closepopup" ></help-modal> 
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

/* Filter */
import SearchFilter from "../../components/filter/SearchFilter";
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import OnlyCompany from "../../components/filter/OnlyCompany";
import HelpModal from "../../components/modal/HelpModal";
import CompanyDetailsModal from "../../components/modal/CompanyDetailsModal";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    VxCard,    
    SearchFilter,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,
    HelpModal,
    CompanyDetailsModal,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      excelFields: {
          "Date Created": "Date_Created",
          "Form Name": "Form_Name",
          "First Name": "First_Name",
          "Last Name": "Last_Name",
          "Email": "Email_Name",
      },
      excelData: [],
      json_meta: [
          [
          {
              key: "charset",
              value: "utf-8"
          }
          ]
      ],

      /*Help Popup */
      popupActive: false,
  
      //Search Term
      searchTerm: "",

      /* Filter Select */
      withinSelected: { withinName: "Form Name" },
      //Within dropdown options
      withinOptions: [
        { withinName: "Form Name" },
        { withinName: "Email", }, 
        { withinName: "First Name", }, 
        { withinName: "Last Name", }, 
      ],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Only Companies
      onlyCompanies: true,

      //Form Captures Table data
      formCapturesData: [],

      perPage: 5,
      recordList: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: '100', value: 100}
      ],
      
      //Contact form popup
      viewFormDataPopupActive: false,
      isViewFormData: false,
      viewFormData: [],

      //Company details popup
      detailspopupActive:false,
      latestVisitorId: "",
      searchLeadDetails: {},
      allVisitorUniqueDetailShort: [],
      pageViewsCompany: {},
      keywords: []
    };
  },
  mounted() {
      let that = this;
      //Filter Location Breakdown datatable record based on search parameter for excel
      setTimeout(() => {
      document.querySelector("#tableDataFormsCaptured .input-search").addEventListener("keyup", function() {
          that.excelData = that.filterExport(true);
          });
      }, 1000);
  },
  created() {
    this.setFromDate();
    this.setToDate();

    this.getSubmittedFormList()
  },
  methods: {
    /* Popup Close */
    closepopup() {
      (this.popupActive = false), (this.detailspopupActive = false);
    },

    //Get Forms Captured
    getSubmittedFormList(){
      let getSubmittedFormListParams = {
        Term: this.searchTerm,
        Within: this.withinSelected.withinName,
        Date_Start: this.fromDate,
        Date_End: this.toDate
      }

      this.$vs.loading()
      this.axios.post("/ws/FormCaptureDashboard/GetSubmittedFormList", getSubmittedFormListParams).then(response => {
        let data = response.data;
        this.formCapturesData = data
        this.$refs.tableDataFormsCaptured.currentx = 1
        this.$refs.tableDataFormsCaptured.searchx = ""
        this.formCapturesData.map(function(manipulatedData) {
          manipulatedData.sortableCreatedDate = new Date(manipulatedData.Date_Processed);
          return manipulatedData;
        });
        this.formCapturesData.sort(this.GetSortOrder("sortableCreatedDate", "DESC")); //Pass the attribute to be sorted on
        this.excelData = this.filterExport();
        this.$vs.loading.close();
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    filterExport(search = false) {
        let data = [];
        let formCapturedData = !search ? this.formCapturesData : this.$refs.tableDataFormsCaptured.datax;

        for (var i = 0; i < formCapturedData.length; i++) {
            data.push({
                "Date_Created": formCapturedData[i].Date_Created,
                "Form_Name": formCapturedData[i].Form_Name,
                "First_Name": formCapturedData[i].First_Name,
                "Last_Name": formCapturedData[i].Last_Name,
                "Email_Name": formCapturedData[i].Email_Name
            });
        }
        return data;
    },
    //Close Individual Submitted Form details popup
    closeViewFormData(){
      this.viewFormDataPopupActive = false
      this.viewFormData=[]
      setTimeout(() => {
        this.isViewFormData = false
      }, 1000);
    },
    //Get details of the Individual Submitted Form
    getIndividualSubmittedForm(id){
      this.$vs.loading();
      this.axios.get("/ws/FormCaptureDashboard/GetIndividualSubmittedForm?id="+id).then(response => {
          let data = response.data;
          this.viewFormData = data
          this.viewFormDataPopupActive = true
          this.$vs.loading.close();
      }).catch(e => {
          this.$vs.loading.close();
          this.showError(e);
      });
    },
    //get Visitor Company Id By Visitor Id and get the details of the Company Details Modal popup
    getCompanyDetails(id){
      this.getVisitorCompanyIdByVisitorId(id)
    },
    //get Visitor Company Id By Visitor Id to get the details of the Company Details Modal popup
    getVisitorCompanyIdByVisitorId(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetVisitorCompanyIdByVisitorId?id="+id).then(response => {
        let data = response.data;
        let newId = data
        this.latestVisitorId = newId
        this.getSearchLeadDetail(newId)
        this.getAllVisitorUniqueDetailShort(newId)
        this.getPageViews(newId)
        this.getKeywords(newId)
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get company details modal popup details
    getSearchLeadDetail(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetSearchLeadDetail?id="+id).then(response => {
        let data = response.data;
        this.searchLeadDetails = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get company details modal popup details
    getAllVisitorUniqueDetailShort(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetAllVisitorUniqueDetailShort?id="+id).then(response => {
        let data = response.data;
        this.allVisitorUniqueDetailShort = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get company details modal popup details
    getPageViews(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetPageViews?id="+id).then(response => {
        let data = response.data;
        this.pageViewsCompany = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //get company details modal popup details
    getKeywords(id){
      this.$vs.loading()
      this.axios.get("/ws/SearchLead/GetKeywords?id="+id).then(response => {
        let data = response.data;
        this.keywords = data
        
        this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    }
  },
  computed: {
    /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
     * showingSearchedDataTo()
		 *  */
		isTableRecordEntriesDisplay(){
			if(this.formCapturesData.length > 0){
				if(this.$refs.tableDataFormsCaptured.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
      if(this.formCapturesData.length > 0){
        if(this.$refs.tableDataFormsCaptured.searchx == null || this.$refs.tableDataFormsCaptured.searchx == ""){
            return false
        }else{
            return true
        }
      }
    },
    showingDataFrom: function () {
      if(this.formCapturesData.length > 0){
        return ((this.$refs.tableDataFormsCaptured.currentx - 1) * this.perPage) + 1 
      }
    },
    showingDataTo: function () {
      if(this.formCapturesData.length > 0){
        let totalPages = this.$refs.tableDataFormsCaptured.getTotalPages
        if(totalPages == this.$refs.tableDataFormsCaptured.currentx){
            return ((this.$refs.tableDataFormsCaptured.currentx - 1) * this.perPage) + this.$refs.tableDataFormsCaptured.datax.length
        }else{
            return ((this.$refs.tableDataFormsCaptured.currentx) * this.perPage)
        }
      }
    },
    totalSearchedData: function () {
      if(this.formCapturesData.length > 0){
        return this.$refs.tableDataFormsCaptured.queriedResults.length
      }
    },
    showingSearchedDataTo: function () {
      if(this.formCapturesData.length > 0){
        let totalPages = this.$refs.tableDataFormsCaptured.getTotalPagesSearch
        if(totalPages == this.$refs.tableDataFormsCaptured.currentx){
            return ((this.$refs.tableDataFormsCaptured.currentx - 1) * this.perPage) + this.$refs.tableDataFormsCaptured.datax.length
        }else{
            return ((this.$refs.tableDataFormsCaptured.currentx) * this.perPage)
        }
      }
    }
  }
};
</script>
